


















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'

@Component({
  components: {
    VueTimepicker,
  },
})
export default class TimePickerBase extends Vue {
  @Prop({ default: '' })
  time!: string

  @Prop({ default: false })
  clearButton?: boolean

  private timeString = this.time

  // time propsのウォッチャー
  @Watch('time')
  onTimeChanged(newTime: string, oldTime: string) {
    this.timeString = newTime
  }

  private setInitialTimeString(): void {
    if (!this.timeString) {
      this.timeString = '00:00'
    }
  }

  private onChange(): void {
    this.$emit('input', this.timeString)
  }
}
